<template>
  <div class="main">
    <div class="condition-filtrate-box">
      <div class="left-item">
        <span>条码号:</span>&nbsp;&nbsp;&nbsp;
        <el-input class="tiaomahao" size="medium" v-model="queryForm.searchValue" placeholder="请扫描或输入条码号"
          ref="usernameRef" />
        <el-button style="margin:20px;" type="primary" size="small" @click="queryInfo" :loading="loading"
          :disabled="loading">开始检测</el-button>
        <span>本次扫描共{{ (staffCheckList.length) }}份</span>
      </div>
    </div>

    <TableModel :is-show-select="true" :loading="loading" :table-data="staffCheckList" table-size="medium">
      <el-table-column prop="barCode" label="条码号" width="200" />
      <el-table-column prop="name" label="姓名" width="80" />
      <el-table-column prop="age" label="年龄" width="80" />
      <el-table-column prop="gender" label="性别" width="80">
        <template slot-scope="{ row }">
          {{ (row.gender == 1 ? '男' : row.gender == 0 ? '女' : '') }}
        </template>
      </el-table-column>
      <el-table-column prop="linkPhone" label="电话" width="120" />
      <el-table-column prop="status" label="状态">
        <template slot-scope="{ row }">
          <el-button class="statusBtn" v-if="row.status == 1" type="success" round
            style="width:50px;height:20px;padding:0;font-size: 12px;">已采样</el-button>
          <el-button class="statusBtn" v-if="row.status == 0" type="success" round
            style="width:50px;height:20px;padding:0;font-size: 12px;">已删除</el-button>
          <el-button class="statusBtn" v-if="(row.status == 2)" type="success" round
            style="width:50px;height:20px;padding:0;font-size: 12px;">已检测</el-button>
          <el-button class="statusBtn" v-if="(row.status == 3)" type="success" round
            style="width:50px;height:20px;padding:0;font-size: 12px;">已报告</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="samplingMode" label="采样方式" v-btn-permission="'008003002001'" width="100" />
      <el-table-column prop="samplingOrgName" label="采样单位" width="200" />
      <el-table-column prop="samplingTime" label="采样时间" width="200">
      </el-table-column>
      <el-table-column prop="samplingName" label="采样医生" width="80">
      </el-table-column>
      <el-table-column prop="detectionTime" label="检验时间" width="200">
      </el-table-column>
      <el-table-column prop="detectionName" label="检验人" width="80">
      </el-table-column>
      <el-table-column prop="detectionCount" label="检测顺序" width="200">
      </el-table-column>
    </TableModel>

    <!-- //页面的遮罩层 -->
    <div id="cover" ref="cover">
      <div id="modal">
        <span>所在部门不是检测机构，无权限使用</span>
      </div>
    </div>

  </div>
</template>
  
<script>
import TableModel from '@/components/TableModel';
import {
  detectionCovidCheck,
} from '../../api/NucleicAcidTest/NucleicAcidTest';

export default {
  name: "StartDetection",
  components: { TableModel },
  data() {
    return {
      isAcidOrg: JSON.parse(localStorage.getItem("orgInfo")).nucleicAcid,
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      queryForm: {
        searchValue: "",
      },
      loading: false,
      staffCheckList: [],
      checkData: [],
    }
  },
  created() {
    //   this.detectionCovidCheck();
  },
  mounted() {
    this.onUsernameRef()
    if (this.isAcidOrg == 0) {
      this.$refs.cover.style.display = "flex";   //显示遮罩层
    }

  },

  methods: {
    /**
     * 自动聚焦用户输入框
     */
    onUsernameRef() {
      this.$nextTick(() => {
        this.$refs.usernameRef.focus()
      })
    },
    // 根据条码号检验
    detectionCovidCheck() {
      let params = {
        barCode: this.queryForm.searchValue
      }
      this.loading = true
      detectionCovidCheck({ params }).then(res => {
        if (res.data.data != null) {
          this.staffCheckList.push(res.data.data)
        }
        console.log(res);
        this.loading = false
      })
    },

    queryInfo() {
      this.pageNumber = 1;
      this.detectionCovidCheck();
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.detectionCovidCheck();
    },

    handleCurrentChange(val) {
      this.pageNumber = val;
      this.detectionCovidCheck();
    },

    changeSize(val) {
      this.pageData.pageSize = val;
      this.checkRecord(this.workerRegId);
    },

    changeCurrent(val) {
      this.pageData.pageNumber = val;
      this.checkRecord(this.workerRegId);
    }
  }
}
</script>
  
<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";

.form-width {
  width: 280px;
}
</style>
  
<style scoped lang="scss">
.el-table>>>td {
  padding: 6px 0 !important;
}

.el-link {
  margin-right: 10px;
}

.content_page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.statusBtn {
  cursor: auto;
}

.statusBtn2 {
  cursor: auto;
}

/* .statusBtn:hover {
    background: rgb(64,158,255);
  }
  .statusBtn2:hover {
    background: rgb(245,108,108);
  } */
.tiaomahao {
  width: 300px;
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
}

#cover {
  position: absolute;
  right: 0px;
  left: 0;
  top: 0px;
  bottom: 0;
  background: rgba(203, 200, 200, 0.4);
  height: 100%;
  filter: alpha(opacity=60);
  // opacity: 0.6;
  // display: none;
  z-Index: 1;
  display: flex;
  display: none;
  justify-content: center;
  align-items: center;
}

#modal {
  color: rgb(91, 91, 91);
  font-weight: bold;
  cursor: pointer;
  font-size: 25px;
}
</style>
  