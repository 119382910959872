import { render, staticRenderFns } from "./StartDetection.vue?vue&type=template&id=639626a6&scoped=true&"
import script from "./StartDetection.vue?vue&type=script&lang=js&"
export * from "./StartDetection.vue?vue&type=script&lang=js&"
import style0 from "./StartDetection.vue?vue&type=style&index=0&id=639626a6&scoped=true&lang=scss&"
import style1 from "./StartDetection.vue?vue&type=style&index=1&id=639626a6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639626a6",
  null
  
)

export default component.exports